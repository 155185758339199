

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">

    <el-card class="SearchBox">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="指定活动">
          <el-select v-model="searchForm.activeId" placeholder="请选择活动">
            <el-option v-for="activ in activeDrops" :key="activ.value" :value="activ.value" :label="activ.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="searchForm.state" placeholder="请选择订单状态">
            <el-option v-for="item in orderStateDrops" :key="item.value" :value="item.value" :label="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input placeholder="请输入用户昵称" v-model="searchForm.appusername" clearable />
        </el-form-item>
        <el-form-item label="订单号">
          <el-input placeholder="请输入订单号" v-model="searchForm.orderNo" clearable />
        </el-form-item>
        <el-form-item label="渠道来源">
          <el-select v-model="searchForm.activeSceneId" placeholder="请选择渠道">
            <el-option v-for="scene in activeSceneDrops" :key="scene.value" :value="scene.value" :label="scene.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="dataBinding">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card shadow="never" class="border-none">
      <el-table :data="tableData" stripe fit>
        <el-table-column prop="id" label="" width="80">
          <template slot-scope="scope">
            <el-avatar size="large" :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="id" label="编号" /> -->
        <el-table-column prop="nickName" label="用户昵称" />
        <el-table-column prop="orderNo" label="订单编号" />
        <el-table-column prop="tradeOrderNo" label="微信单号" />
        <el-table-column prop="couponNo" label="优惠券码" />
        <el-table-column prop="orderAmount" label="订单总额" />
        <el-table-column prop="couponDiscount" label="优惠金额" />
        <el-table-column prop="realAmount" label="实际支付" />
        <el-table-column prop="state" label="订单状态">
          <template slot-scope="scope">
            <el-tag v-show="scope.row.state===1" type="success" disable-transitions>已支付</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="payTime" label="支付日期" />
        <el-table-column prop="activitySceneName" label="渠道来源" />

      </el-table>

      <div class="margin-t16 text-right">
        <el-pagination background layout="prev, pager,next" :total="totalCount" :current-page="searchForm.pageIndex"
          :page-size="searchForm.pageSize" @current-change="handleCurrentChange">
        </el-pagination>
      </div>

    </el-card>
  </div>
</template>
<script>
import { getOrderList } from '@/service/statistics.js';
import { activities, scenes } from '@/service/dropdown.js';

export default {
  data() {
    return {
      loading: false,
      searchOpen: false,
      totalCount: 0,
      activeDrops: [],
      activeSceneDrops: [{ label: '--全部--', value: 0 }],
      orderStateDrops: [
        { label: '--全部--', value: -1 },
        { label: '待支付', value: 0 },
        { label: '已支付', value: 1 },
        { label: '订单关闭', value: 99 }
      ],
      searchForm: {
        pageIndex: 1,
        pageSize: 20,
        activeId: 0,
        appusername: '',
        orderNo: '',
        state: -1,
        activeSceneId: 0,
      },
      tableData: []
    };
  },
  async created() {
    await this.getActivitiesDropdown();
    await this.dataBinding();
    await this.getSceneDropdown();
  },

  methods: {
    async getSceneDropdown() {
      return new Promise((resolve, reject) => {
        scenes().then(res => {
          if (res.code == 0) {
            this.activeSceneDrops = [{ label: '--全部--', value: 0 }];
            res.data.forEach(element => {
              this.activeSceneDrops.push(element);
            });
            resolve();
          }
        })
      });
    },
    //获取活动下拉数据
    async getActivitiesDropdown() {
      return new Promise((resolve, reject) => {
        activities().then(async res => {
          if (res.code == 0) {
            this.activeDrops = res.data;
            this.searchForm.activeId = res.data[0].value;

            resolve();
          }
        });
      })
    },
    //加载页面数据
    async dataBinding() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        getOrderList(this.searchForm).then(res => {
          this.totalCount = res.data.totalCount;
          this.tableData = res.data.data;
          this.loading = false;
          resolve();
        });
      })
    },
    //页码切换
    handleCurrentChange(val) {
      if (this.searchForm.pageIndex != val) {
        this.searchForm.pageIndex = val;
        this.dataBinding();
      }
    }
  }
};
</script>
<style scoped>
.Middle {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.SearchBox {
  margin-bottom: 30px;
}
</style>